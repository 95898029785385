#alert {
    inset: 0 0 0 0;
    position: fixed;
    z-index: 1000;
    background-color: #8885;
    display: flex;
    justify-content: center;
    align-items: center;
}

#alert-box {
    max-width: 70vw;
    min-width: 60vw;
    padding: 20px;
    background-color: #FFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
}
.alert-box-button {
    background-color: #FD612A;
    border-radius: 8px;
    color: #FFF;
    padding: 8px 16px;
    min-width: 50%;
}
.alert-box-button.cancel {
    background-color: rgb(156 163 175);
}
#alert-confirm {
    display: flex;
    column-gap: 10%;
    justify-content: center;
    width: 100%;
}
#alert-confirm > .alert-box-button {
    min-width: 25%;
}
