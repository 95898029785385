:root {
  --on: #4CAF50;
  --off: #F00;
}

.toggle {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.toggle.disabled {
  opacity: 0.3;
}

.toggle input {
  display: none;
}

.toggle_container {
  position: relative;
  border-radius: 20px;
  border-width: 1px;
  border-color: #999;
  height: 20px;
  width: 38px;
  display: flex;
  align-items: center;
  padding: 2px;
  background-color: var(--off);
  transition: all ease 0.3s;
}

.toggle_container:before {
  content: '';
  position: absolute;
  display: block;
  left: 2px;
  height: 16px;
  aspect-ratio: 1;
  border-radius: 50%;
  border-color: #999;
  border-width: 1px;
  background-color: #FFF;
  transition: all ease 0.3s;
}

.toggle .toggle_container.checked {
  background-color: var(--on);
}

.toggle .toggle_container.checked:before {
  left: auto;
  right: 2px;
}