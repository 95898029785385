#header-layout {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
}
#header-layout > *:first-child {
    display: flex;
    justify-content: flex-start;
}
#header-layout > *:last-child {
    display: flex;
    justify-content: flex-end;
    column-gap: 2rem;
}
