#user_info-header {
    z-index: 9;
}
#user_info-page_navigator {
    position: fixed;
    bottom: 5rem;
    left: 0;
    background: linear-gradient(#EEE0, #EEE);
    width: 100%;
    padding: 0.5rem 0;
}
