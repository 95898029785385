.single_filter {
    position: relative;
}
.single_filter * {
    width: inherit;
    text-align: left;
}
.single_filter-selected {
    border-radius: 4px;
    outline: #CCC 1px solid;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    background-color: #FFF;
    column-gap: 8px;
}
.single_filter-selected.open {
    border-radius: 4px 4px 0 0;
}
.single_filter-selected.active {
    outline: rgb(253 97 42) 1px solid;
    color: rgb(253 97 42);
}
.single_filter-arrow {
    background: url("../assets/arrow_down.svg") no-repeat center;
    background-size: contain;
    width: 12px;
    height: 12px;
}
.icon-check {
    background: url("../assets/check.svg") no-repeat center;
    background-size: contain;
    width: 12px;
    height: 12px;
}
.single_filter-options {
    position: absolute;
    right: 0;
    background-color: #FFF;
    border-radius: 0 0 0.5rem 0.5rem;
    outline: #CCC 1px solid;
    max-height: 50vh;
    width: 150px;
    overflow: scroll;
}
.single_filter-category {
    border-bottom: 1px solid #EEE;
    margin-bottom: -1px
}
.single_filter-category:last-child {
    border-bottom: 0px;
    margin-bottom: 0px;
}
.single_filter-option {
    padding: 6px 10px 6px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.single_filter-option.single {
    padding: 6px 10px 6px 12px;
}
.single_filter-option:hover {
    background-color: #CCC;
}
.single_filter-subtitle {
    padding: 8px 0 8px 12px;
}
