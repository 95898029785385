#filter {
  width: 100%;
  background-color: #DDDDDD;
  position: sticky;
  top: 3rem;
  z-index: 9;
}

[id*=filter-options] {
  width: 100%;
  display: grid;
  column-gap: 3%;
}

[id*=filter-options]>button {
  padding: 0.5rem 2px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  transition: padding 0.2s, font-size 0.2s;
}

[id*=filter-options]>button.active {
  background-color: #CCC;
}

[id*=filter-options]>button[aria-checked="true"]>div {
  color: red;
}

.filter-options-arrow {
  background: url("../assets/arrow_down.svg") no-repeat center;
  background-size: contain;
  width: 12px;
  height: 12px;
}

[id*=filter-options]>button[aria-checked="true"]>div.filter-options-arrow {
  filter: invert(24%) sepia(100%) saturate(7388%) hue-rotate(9deg) brightness(95%) contrast(127%);
}

[id*=filter-block] {
  width: 100%;
  height: 100%;
  background-color: #0005;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

[id*="filter-details"] {
  position: absolute;
  background-color: #FFF;
}

#filter-details {
  width: 100%;
  left: 0;
  padding: 10px 20px 45px;
  max-height: 60vh;
  display: flex;
}

#filter-details-sorter {
  right: 0;
  width: 22.75%;
}

.filter-details-suboption {
  border-bottom: 2px solid #EEE;
  padding-left: 24px;
}

.filter_detail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.filter-display {
  overflow: scroll;
  flex: 1;
}

.filter-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
}

#filter-content-bottom {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.filter-content-button {
  display: block;
  padding: 4px 16px;
  border-radius: 8px;
  background-color: #FD612A;
  font-size: 16px;
  color: #FFF;
  cursor: pointer;
}

.filter-content-button.grey {
  background-color: #666666;
}

.filter-sorter-button {
  display: block;
  padding: 8px 12px;
  width: 100%;
}

.filter-sorter-button:hover {
  background-color: rgba(9, 30, 66, 0.2);
}

#filter-sorter-selected {
  color: rgba(253, 97, 42);
}

#filter-sorter-selected:hover {
  color: rgba(253, 97, 42, 0.5);
}