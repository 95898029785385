#news {
    width: 100%;
    position: fixed;
    top: 3rem;
    bottom: 0;
    left: 0;
    z-index: 1000;
    padding: 0.5rem 2rem 3rem;
    pointer-events: visible;
    background: #0005;
    display: flex;
    justify-content: center;
}
#news-container {
    box-shadow: 0 0 10px #0005;
    background-color: #FFF;
    max-width: 768px;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    row-gap: 0.5rem;
    position: relative;
}
#news-title {
    font-size: 2rem;
    align-self: center;
    font-weight: bold;
}
#news-content {
    flex: 1;
    width: 100%;
    padding: 0 30px;
    margin: 1rem 0;
    position: relative;
    overflow: scroll;
}
#news-content-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}
#news-content-date {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    background-color: #FD612A;
    color: #FFF;
    padding: 0.5rem;
    border-radius: 4px;
}
#news-content-date > div:first-child {
    display: flex;
    justify-content: space-between;
    column-gap: 4px;
}
#news-content-date > div:last-child {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
}
#news-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 16px;
    cursor: pointer;
}
#news-close_button {
    display: block;
    padding: 4px 16px;
    border-radius: 8px;
    background-color: grey;
    font-size: 16px;
    color: #FFF;
    cursor: pointer;
}
.news-container-arrow {
    width: 15px;
    aspect-ratio: 15 / 25;
    background-color: #000;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
}
#arrow-left {
    left: 10px;
    background: url("../assets/arrow_left.svg") no-repeat left;
}
#arrow-right {
    right: 10px;
    background: url("../assets/arrow_right.svg") no-repeat right;
}
