ol {
  list-style: decimal;
  padding-left: 40px;
  margin: 0;
}

ol li {
  margin: 10px 0;
}

img.signature {
  max-width: 200px;
  margin: 0 auto;
}