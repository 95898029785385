:root .MuiFormControlLabel-label {
  margin-left: -6px;
}

p.mb-n2 {
  margin-bottom: -6px;
}

.p-20p {
  padding: 20%;
}

.bg-whatsapp {
  background: #4FCE5D;
}

#cancelTag {
    background-color: #9CA2AD;
    color: #FFF;
}

#share-button {
    bottom: 5rem;
    border: 1px solid #999999;
    background: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
}

#share-icon {
    background: url("../../assets/share.svg") no-repeat center;
    background-size: contain;
    height: 50%;
    aspect-ratio: 1 / 1;
}

#newyear-button {
    animation: button-effect 10s infinite;
    color: white;
    font-weight: 400;
}
@keyframes button-effect{
    0% {
        background-color: #FD612A;
    }
    95% {
        background-color: #FD612A;
    }
    97.5% {
        background-color: #FEB018;
    }
    100% {
        background-color: #FD612A;
    }
}
#firecracker-icon {
    background: url("../../assets/firecracker.png") no-repeat center;
    background-size: contain;
    height: 1.5rem;
    aspect-ratio: 1 / 1;
}
