#root {
  margin-left: auto;
  margin-right: auto;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 0px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
  background-color: #eeeeee;
  color: black;
}

*:focus {
  outline: none;
}

.specFont {
  font-family: 'Zen Dots', sans-serif;
}

.opacity-54 {
  opacity: 0.54;
}

.ratio-1\/1 {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.ratio-16\/9 {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.ratio-in {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wholebg {
  position: relative;
}

.wholebg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-repeat: repeat;
  background-size: 100% auto;
  z-index: -1;
}

.logobg {
  position: relative;
}

.logobg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/road.jpg");
  background-repeat: repeat;
  background-size: contain;
}

.bg1 {
  position: relative;
}

.bg1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.bg2 {
  position: relative;
}

.bg2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: 100% 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.appWidth {
  width: 96%;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}

.gradiBtn {
  background: linear-gradient(225deg, rgba(241, 148, 108, 1) 0%, #FD612A 100%);
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 400;
}

.greenBtn {
  background: #4FCE5D;
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 400;
}

.greyBtn {
  background: #666666;
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 400;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

@tailwind base;
@tailwind components;
@tailwind utilities;