.remark {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.taxi_info-view {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
}
